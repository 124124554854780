<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapState } from "vuex";

export default {
  name: "PasswordPolicyManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("passwordpolicies", {
      contents: state => state.policies
    })
  },

  methods: {
    ...mapActions("serviceproviders", {
      fetchAccountsForServiceProviders: "fetchAccountsForServiceProviders"
    }),

    initContent() {
      return {
        policy_name: "",
        rotation_duration: 90,
        two_fa: false,
        concurrent_login: true,
        total_characters: 8,
        upper_case: true,
        lower_case: true,
        numbers: true,
        special_characters: true,
        check_used_password: false,
        is_default_sp_policy: false,
        is_default_ac_policy: false,
        pwned_password: false
      };
    }
  },

  mounted() {
    this.contentType = "Password Policy";
    this.primaryKey = "password_policy_id";
  }
};
</script>

<style lang="scss" scoped></style>
