<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Policy Name") }}</div>
    <div class="details">{{ contentFormInfo.policy_name }}</div>
    <div class="titles">{{ __("Rotation Duration") }}</div>
    <div class="details">{{ contentFormInfo.rotation_duration }}</div>
    <div class="titles">{{ __("Has 2FA Enabled") }}</div>
    <div class="details">{{ contentFormInfo.two_fa }}</div>
    <div class="titles">{{ __("Minimum Length") }}</div>
    <div class="details">{{ contentFormInfo.total_characters }}</div>
    <div class="titles">{{ __("Concurrent Login") }}</div>
    <div class="details">{{ contentFormInfo.concurrent_login }}</div>
    <div class="titles">{{ __("Include Upper Case") }}</div>
    <div class="details">{{ contentFormInfo.upper_case }}</div>
    <div class="titles">{{ __("Include Lower Case") }}</div>
    <div class="details">{{ contentFormInfo.lower_case }}</div>
    <div class="titles">{{ __("Include Numbers") }}</div>
    <div class="details">{{ contentFormInfo.numbers }}</div>
    <div class="titles">{{ __("Include Special Characters") }}</div>
    <div class="details">{{ contentFormInfo.special_characters }}</div>
    <div class="titles">{{ __("Allow Used Password") }}</div>
    <div class="details">{{ contentFormInfo.check_used_password }}</div>
    <div class="titles">{{ __("Allow Compromised Passwords") }}</div>
    <div class="details">{{ contentFormInfo.pwned_password }}</div>
    <div class="titles">{{ __("Total Accounts Using This Policy") }}</div>
    <div class="details">{{ contentFormInfo.ac_count }}</div>
    <el-button @click="handleEdit" class="editBtn" v-show="this.canWrite()">{{
      __("Edit")
    }}</el-button>
    <el-button
      v-show="this.canWrite()"
      @click="handleDelete(contentFormInfo)"
      style="margin-top: 10px"
      class="deleteBtn"
      >{{ __("Delete") }}
    </el-button>
    <el-dialog
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isLoading"
    >
      <el-scrollbar :native="false" style="margin-top: 34px">
        <div style="max-height: calc(100vh - 34px);">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="12" :offset="6">
                <page-header
                  style="padding-left: 0"
                  :title="__('Password Policy')"
                  :contentId="id"
                />
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Policy Name')" prop="policy_name">
                  <el-input v-model="contentForm.policy_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Is Default Policy For Service Provider Admins')"
                  prop="is_default_sp_policy"
                >
                  <el-checkbox v-model="contentForm.is_default_sp_policy">{{
                    __("Yes")
                  }}</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Is Default Policy For Accounts')"
                  prop="is_default_ac_policy"
                >
                  <el-checkbox v-model="contentForm.is_default_ac_policy">{{
                    __("Yes")
                  }}</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Rotation Duration (Days)')"
                  prop="rotation_duration"
                  required
                >
                  <el-input-number
                    v-model="contentForm.rotation_duration"
                    :step="10"
                    :min="90"
                    :max="360"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item label="2FA" prop="two_fa">
                  <el-checkbox v-model="contentForm.two_fa">{{
                    __("Required")
                  }}</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Concurrent Login')"
                  prop="concurrent_login"
                >
                  <el-checkbox v-model="contentForm.concurrent_login">{{
                    __("Enabled")
                  }}</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Minimum Length (Characters)')"
                  prop="total_characters"
                  required
                >
                  <el-input-number
                    v-model="contentForm.total_characters"
                    :min="8"
                    :max="50"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Upper Case')" prop="upper_case">
                  <el-checkbox v-model="contentForm.upper_case">{{
                    __("Required")
                  }}</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Lower Case')" prop="lower_case">
                  <el-checkbox v-model="contentForm.lower_case">{{
                    __("Required")
                  }}</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Numbers')" prop="numbers">
                  <el-checkbox v-model="contentForm.numbers">{{
                    __("Required")
                  }}</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Special Characters (one from @$!%*#?&)')"
                  prop="special_characters"
                >
                  <el-checkbox v-model="contentForm.special_characters">{{
                    __("Required")
                  }}</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Pwned Passwords (check compromised passwords)')"
                  prop="pwned_password"
                >
                  <el-checkbox v-model="contentForm.pwned_password">{{
                    __("Required")
                  }}</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Allow Used Password')"
                  prop="check_used_password"
                >
                  <el-checkbox v-model="contentForm.check_used_password">{{
                    __("No")
                  }}</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              :disabled="!this.canWrite()"
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :primaryKey="id"
            />
            <el-button @click="handleCancelBtn" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton
  },
  data() {
    return {
      rules: {
        policy_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Policy name is required")
          }
        ]
      }
    };
  },

  computed: {
    ...mapState("passwordpolicies", {
      isLoading: state => state.isLoading
    })
  },

  methods: {
    ...mapActions("passwordpolicies", {
      createPasswordPolicy: "createPasswordPolicy",
      updatePasswordPolicy: "updatePasswordPolicy",
      deleteContentMethod: "deletePasswordPolicy"
    }),
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process =
            this.id === -1
              ? this.createPasswordPolicy
              : this.updatePasswordPolicy;
          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Password Policy created successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Password Policy updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },
    handleCancelBtn() {
      this.resetForm("contentForm");
      this.handleCancel();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
